import './AnalyzeLoader.scss';

const CLASS = 'jenna-loader';

const AnalyzeLoader = () => (
    <div className={CLASS}>
        <svg className={`${CLASS}-circular`}>
            <circle
                className={`${CLASS}-circular-path`}
                cx="60"
                cy="60"
                r="40"
                fill="none"
                strokeWidth="4"
                strokeMiterlimit="10"
            />
        </svg>
        <svg
            className={`${CLASS}-drop`}
            width="40px"
            height="40px"
            viewBox="0 0 19 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.55585 0L0.342773 14.9968H18.7437L9.55585 0ZM9.55585 5.11557L14.2018 12.7054H4.91306L9.55585 5.11557Z"
                fill="#347AF0"
            />
        </svg>
    </div>
);

export default AnalyzeLoader;
