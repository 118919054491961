import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import resources from './resources.json';

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        fallbackLng: 'en-GB',
        resources,
        ns: 'jenna_webchat_ui',
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
