import clsx from 'clsx';

import './Button.scss';

const CLASS = 'jenna-button';

const Button = ({ className, onClick, text, disabled, children, ...props }) => {
    return (
        <button
            className={clsx(CLASS, { [`${CLASS}-disabled`]: disabled }, className)}
            onClick={onClick}
            disabled={disabled}
            {...props}
        >
            {text || children}
        </button>
    );
};

export default Button;
