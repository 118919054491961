import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import socket from 'utils/socket/socket';

const useConversationEvents = (candidateData, messages, onUpdate) => {
    const [searchParams] = useSearchParams();
    const source = searchParams.get('source')?.split('?')?.[0] || 'jenna_web';
    const { botId } = useParams();

    useEffect(() => {
        socket.on('connection', (data) => {
            const { id, positionId } = candidateData;

            socket.emit('startChat', {
                source,
                botId,
                positionId,
                candidateId: id,
                chatHistory: id && messages.length === 0,
            });
        });

        //remove text 'send' on phones
        socket.on('bot message', (data) => {
            const { uuid, candidateUid, type, qType, message } = data;
            socket.emit('messageSuccess', { uuid, qType });

            let newMsg = { candidateUid };
            if (type === 'in') {
                newMsg.text = message;
                newMsg.type = type;
            } else {
                newMsg = data;
                if (!newMsg.type) newMsg.type = 'out';
            }
            onUpdate(newMsg);
        });

        socket.on('firstname', (data) => {
            const msg = data.message;
            onUpdate({
                qType: 'firstname',
                type: 'out',
                message: { text: msg, type: 'free text' },
            });
        });

        socket.on('lastname', (data) => {
            const msg = data.message;
            onUpdate({
                qType: 'lastname',
                type: 'out',
                message: { text: msg, type: 'free text' },
            });
        });
        return () => {
            socket.off('connection');
            socket.off('bot message');
            socket.off('firstname');
            socket.off('lastname');
        };
    }, []);
};

export default useConversationEvents;
