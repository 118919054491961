import './Typing.css';

const Typing = () => (
    <div className="typingWrapper">
        <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
);

export default Typing;
