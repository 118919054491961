import './ConversationButtonGroup.scss';

const CLASS = 'jenna-conversation-button-group';

const ConversationButtonGroup = ({ className, isShown = true, children }) => {
    if (!isShown) return null;

    return <div className={`${CLASS} ${className}`}>{children}</div>;
};

export default ConversationButtonGroup;
