import { useState } from 'react';

import { flowActions } from 'utils/resources/qTypes';
import Button from '../Button/Button';

import './ConversationButton.scss';

const CLASS = 'jenna-conversation-button';

const ConversationButton = ({ onSendMessage, option }) => {
    const [clicked, setClicked] = useState(false);
    const { text, action, filter, preferred } = option;

    const handleClick = () => {
        if (clicked) return;

        let reply = {
            text,
            action: action || flowActions.quick_reply,
            filter: !!filter,
            preferred,
        };

        document.getElementById('input')?.focus();
        onSendMessage(reply);
        setClicked(true);
    };

    return <Button className={CLASS} onClick={handleClick} text={text} disabled={clicked} />;
};

export default ConversationButton;
