import { BrowserRouter, Routes, Route } from 'react-router-dom';

import App from '../App';
import ThankYou from 'pages/ThankYou/ThankYou';
import NotFound from 'pages/NotFound/NotFound';

const Router = () => (
    <BrowserRouter>
        <Routes>
            <Route path="/thankYou" element={<ThankYou />} />
            <Route path="/:botId" element={<App />} />
            <Route element={<NotFound />} />
        </Routes>
    </BrowserRouter>
);

export default Router;
