import { useForm } from 'react-hook-form';

import socket from 'utils/socket/socket';
import { regex } from 'utils/regex';

import { Button } from 'ui/Button';

import './CandidateInfoForm.scss';

const CLASS = 'jenna-candidate-info-form';

const CandidateInfoForm = ({ candidateData, setShowForm }) => {
    const { name, location, phone, email, linkedin } = candidateData;
    const defaultValues = { name, location, phone, email, linkedin };

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm({ mode: 'onChange', defaultValues });

    const onCancel = () => {
        reset();
        setShowForm(false);
    };

    const onSubmit = (data) => {
        const [firstName, lastName] = data.name.split(' ');
        socket.emit('updateCandidateInfo', { ...data, first_name: firstName, last_name: lastName, id: candidateData.id });
        setShowForm(false);
    };

    return (
        <form className={CLASS} onSubmit={handleSubmit(onSubmit)}>
            <input
                className={`${CLASS}-input ${errors.name && 'error'}`}
                id="personName"
                type="text"
                placeholder="Enter your name..."
                {...register('name', { pattern: regex.name })}
            />
            <input
                className={`${CLASS}-input`}
                id="personLocation"
                type="text"
                placeholder="eg. US, New York"
                {...register('location')}
            />
            <input
                className={`${CLASS}-input ${errors.phone && 'error'}`}
                id="personPhone"
                type="tel"
                placeholder="Enter your phone..."
                {...register('phone', { pattern: regex.phone })}
            />
            <input
                className={`${CLASS}-input ${errors.email && 'error'}`}
                id="personEmail"
                type="text"
                placeholder="Enter your Email address..."
                {...register('email', { pattern: regex.email })}
            />
            <input
                className={`${CLASS}-input ${errors.linkedin && 'error'}`}
                id="personLinkedin"
                type="text"
                placeholder="Enter your Linkedin link..."
                {...register('linkedin', { pattern: regex.linkedin })}
            />
            <div>
                <Button className={`${CLASS}-button cancel`} onClick={onCancel} text="Cancel" />
                <Button className={`${CLASS}-button`} type="submit" disabled={!isValid} text="Save" />
            </div>
        </form>
    );
};

export default CandidateInfoForm;
