import { useState } from 'react';
import FadeIn from 'react-fade-in';

import { Button } from 'ui/Button';
import BotAvatar from 'components/Middle/BotAvatar/BotAvatar';
import AnalyzeLoader from 'components/Middle/AnalyzeLoader/AnalyzeLoader';
import { flowActions } from 'utils/resources/qTypes';
import { useSkipQuestion } from 'ui/Button';

import './BotTextMessage.scss';

const CLASS = 'jenna-bot-message';

const BotTextMessage = ({ onSendMessage, candidateData, shouldShowBotAvatar, botAvatar, msg, isActive, message }) => {
    const [isVisible, setIsVisible] = useState(true);

    const onSkip = useSkipQuestion(onSendMessage, candidateData.id);

    const renderContent = () => {
        if (message === 'analyzing') {
            setTimeout(() => setIsVisible(false), 3000);
            return <AnalyzeLoader />;
        }
        return <p className={`${CLASS}-content-text`}>{message}</p>;
    };

    return (
        isVisible && (
            <FadeIn>
                <div className={CLASS}>
                    <BotAvatar avatar={botAvatar} shouldShow={shouldShowBotAvatar} />
                    <div className={`${CLASS}-content`}>
                        {renderContent()}
                        {msg?.allowSkip && msg?.qType === flowActions.linkedin && isActive && (
                            <Button className={`${CLASS}-content-button`} onClick={onSkip} text="Skip" />
                        )}
                    </div>
                </div>
            </FadeIn>
        )
    );
};

export default BotTextMessage;
