import { useState } from 'react';

import { useModals } from 'context';
import BotAvatar from 'components/Middle/BotAvatar/BotAvatar';
import { ConversationButtonGroup, Button } from 'ui/Button';

import './TermsMessage.scss';

const CLASS = 'jenna-terms-message';

const TermsMessage = ({ updateAccept, isActive, botAvatar, shouldShowBotAvatar }) => {
    const [shouldShowButtons, setShouldShowButtons] = useState(false);

    const { setTerms } = useModals();

    const termsClickHandler = () => {
        setShouldShowButtons(true);
        setTerms();
    };

    const handleAccept = (event) => {
        const answer = event.currentTarget.textContent;
        updateAccept(answer);
    };

    if (!isActive) return null;

    return (
        <>
            <div className={CLASS}>
                <BotAvatar avatar={botAvatar} shouldShow={shouldShowBotAvatar} />
                <Button className={`${CLASS}-show`} onClick={termsClickHandler}>
                    Terms and conditions <i className="fas fa-long-arrow-alt-right"></i>
                </Button>
            </div>
            <ConversationButtonGroup isShown={shouldShowButtons}>
                <Button className={`${CLASS}-accept`} onClick={handleAccept} text="Accept" />
                <Button className={`${CLASS}-reject`} onClick={handleAccept} text="Reject" />
            </ConversationButtonGroup>
        </>
    );
};

export default TermsMessage;
