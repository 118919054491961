import { useParams } from 'react-router-dom';

import { flowActions } from 'utils/resources/qTypes';

const useSkipQuestion = (onSendMessage, candidateId) => {
    const { botId } = useParams();

    const skipQuestion = () => {
        onSendMessage({
            botId,
            candidateId,
            action: flowActions.skip,
            text: 'Skip',
        });
    };

    return skipQuestion;
};

export default useSkipQuestion;
