import { useEffect } from 'react';
import clsx from 'clsx';

import socket from 'utils/socket/socket';
import { flowActions } from 'utils/resources/qTypes';

import { ConversationButtonGroup, Button, useSkipQuestion } from 'ui/Button';
import './UploadButton.scss';

const CLASS = 'jenna-user-avatar-button';

const UploadButton = ({
    hasImage,
    isLoading,
    setIsLoading,
    isActive,
    botId,
    candidateId,
    message,
    handleImageClick,
    onSendMessage,
}) => {
    useEffect(() => {
        if (hasImage && !isLoading && isActive) setTimeout(() => skipAnswer(), 500);

        const onImageUpload = (data) => {
            setIsLoading(false);
            socket.emit('client message', {
                botId,
                candidateId,
                action: flowActions.next,
                text: 'Image Uploaded!',
                url: data.url,
            });
        }

        socket.on('candidateImg', onImageUpload);

        return () => socket.off('candidateImg', onImageUpload);
    }, [hasImage]);

    const skipAnswer = useSkipQuestion(onSendMessage, candidateId);

    return (
        <ConversationButtonGroup>
            {isActive ? (
                <>
                    <Button
                        className={clsx(`${CLASS}-upload`, { [`${CLASS}-uploading`]: isLoading })}
                        onClick={handleImageClick}
                    >
                        {!hasImage && !isLoading && 'Upload'}
                        {(isLoading || hasImage) && 'Uploading image...'}
                    </Button>
                    {message.allowSkip && <Button className={`${CLASS}-upload`} onClick={skipAnswer} text="Skip" />}
                </>
            ) : (
                <Button className={`${CLASS}-upload ${CLASS}-uploaded`} text="Image uploaded" />
            )}
        </ConversationButtonGroup>
    );
};
export default UploadButton;
