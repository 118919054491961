import { useState, useRef } from 'react';
import FadeIn from 'react-fade-in';

import socket from 'utils/socket/socket';
import useConversationEvents from 'utils/socket/useConversationEvents';
import { flowActions } from 'utils/resources/qTypes';

import MessageList from './MessageList';
import Input from 'components/Middle/Input/Input';
import Footer from 'components/Middle/Footer/Footer';

import './Middle.scss';

const CLASS = 'jenna-middle';
let scrollTimeout;

const Middle = ({ botAvatar, botId, updateCandidate, candidateData }) => {
    const [messages, setMessages] = useState([]);
    const [inputFocus, setInputFocus] = useState(false);

    const messageContainerRef = useRef(null);

    const onUpdate = (newMsg) => {
        setMessages((prevState) => updateMessages(prevState, newMsg));

        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => {
            messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        }, 110);
    };

    useConversationEvents(candidateData, messages, onUpdate);

    const getLastQuestion = (msgs) => {
        return msgs
            .slice()
            .reverse()
            .find((msg) => msg.type === 'out' && msg.type !== 'typing');
    };

    const updateMessages = (prevMessages, newMsg) => {
        const newMessages = prevMessages.filter((msg) => msg.type !== 'typing');
        if (newMsg.action !== flowActions.next) {
            newMessages.push(newMsg);
        }

        if (newMessages.slice(-1).pop().type === 'in') {
            newMessages.push({
                type: 'typing',
                message: {
                    text: 2,
                },
            });
        }

        return newMessages;
    };

    const onSendMessage = (actionData) => {
        const clientMsg = createMessage(actionData);
        socket.emit('client message', clientMsg);
        onUpdate({ ...clientMsg, type: 'in' });
    };

    const toggleFocus = () => {
        setInputFocus((prevState) => !prevState);
    };

    const createMessage = (fields) => {
        const lastQuestion = getLastQuestion(messages);
        const msg = {
            botId,
            candidateUid: lastQuestion.candidateUid,
            botReply: lastQuestion,
            candidateId: candidateData.id,
            qType: lastQuestion.qType,
            action: lastQuestion.qType,
            type: 'in',
            ...fields,
        };
        return msg;
    };

    const updateAccept = (text) => {
        const clientMsg = createMessage({ text });
        socket.emit('policy', clientMsg);
        onUpdate({ type: 'in', text });
        updateCandidate({ privacy: text });
    };

    return (
        <div className={CLASS}>
            {botAvatar && (
                <FadeIn>
                    <img className={`${CLASS}-bot-image`} src={botAvatar} alt="bot-avatar" />
                </FadeIn>
            )}
            <div className={`${CLASS}-conversation`}>
                <div className={`${CLASS}-conversation-messages`} ref={messageContainerRef}>
                    <MessageList
                        updateAccept={updateAccept}
                        onSendMessage={onSendMessage}
                        messages={messages}
                        candidateData={candidateData}
                        updateCandidate={updateCandidate}
                        botId={botId}
                    />
                </div>
                <Input
                    botId={botId}
                    onUpdate={onUpdate}
                    onSendMessage={onSendMessage}
                    toggleFocus={toggleFocus}
                    candidateData={candidateData}
                    messages={messages}
                />
                <Footer inputFocus={inputFocus} />
            </div>
        </div>
    );
};

export default Middle;
