import { useState, useRef } from 'react';
import FadeIn from 'react-fade-in';

import { nonFlowActions } from 'utils/resources/qTypes';
import socket from 'utils/socket/socket';

import './Input.scss';

const CLASS = 'jenna-input';

const menuOptions = [
    { text: 'View Open Positions', action: nonFlowActions.open_positions },
    { text: 'Powered by Jenna AI', action: nonFlowActions.powered_by },
    { text: 'Stop Applying', action: nonFlowActions.stop_applying },
];

const Input = ({ candidateData, messages, botId, onUpdate, onSendMessage, toggleFocus }) => {
    const [inputText, setInputText] = useState('');
    const [showMenu, setShowMenu] = useState(false);

    const input = useRef(null);

    const selectOption = ({ text, action }) => {
        const newMsg = { text, type: 'in' };
        socket.emit('client message', {
            text,
            action,
            candidateId: candidateData.id,
            botId,
        });

        onUpdate(newMsg);
        input.current.focus();
    };

    const toggleMenu = (e) => {
        setShowMenu((prevState) => !prevState);
        input.current.focus();
    };

    const sendMessage = () => {
        if (!inputText.trim()) return;

        onSendMessage({ text: inputText });
        setInputText('');
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
        }
    };

    const { privacy } = candidateData;

    if (privacy !== 'Accept') return null;

    return (
        <FadeIn>
            <div className={CLASS}>
                <div
                    className={`${CLASS}-button-menu ${showMenu && `${CLASS}-button-menu--active`}`}
                    onClick={toggleMenu}
                >
                    <ul className={`${CLASS}-menu ${!showMenu && `${CLASS}-menu--hidden`}`}>
                        {menuOptions.map((option) => (
                            <li key={option.text} onClick={() => selectOption(option)}>
                                {option.text}
                            </li>
                        ))}
                    </ul>
                </div>
                <textarea
                    ref={input}
                    id="input"
                    className={`${CLASS}-field`}
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    onFocus={toggleFocus}
                    onBlur={toggleFocus}
                    onKeyDown={handleKeyPress}
                    placeholder="Type answer here..."
                    autoComplete="off"
                    maxLength="700"
                />
                <div className={`${CLASS}-button-send-message`} onClick={sendMessage}>
                    <p className={`${CLASS}-button-send-message-text`}>Send</p>
                </div>
            </div>
        </FadeIn>
    );
};

export default Input;
