import { useState, useRef, useEffect, cloneElement } from 'react';

import socket from 'utils/socket/socket';

import './ImageUpload.scss';

const CLASS = 'jenna-user-avatar';

const ImageUpload = ({ candidateImg, hasImage, botId, candidateId, updateCandidate, className, children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const imageInput = useRef(null);

    useEffect(() => {
        const imageUploaded = (data) => {
            if (data.error) return;
            updateCandidate((prevState) => ({ ...prevState, candidateImg: data.url }));
            setIsLoading(false);
        }

        socket.on('candidateImg', imageUploaded);

        socket.on('deleteCandidateImg', onImageDeleted);

        return () => {
            socket.off('candidateImg', imageUploaded);
            socket.off('deleteCandidateImg', onImageDeleted);
        };
    }, []);

    const uploadImage = (event) => {
        const file = event.target.files[0];
        const fileSizeMb = file.size / 1024 / 1024;
        if (fileSizeMb > 10) return;

        socket.emit('upload img', {
            botId: botId,
            candidateId: candidateId,
            photo: file,
        });
        setIsLoading(true);
    };

    const onImageDeleted = (data) => {
        setIsLoading(false);
        if (data.success === 'ok') {
            updateCandidate((prevState) => ({ ...prevState, candidateImg: '', hasImage: false }));
        } else {
            console.error('error while deleting');
        }
    };

    const removeImage = () => {
        setIsLoading(true);

        socket.emit('deleteCandidateImg', {
            candidateId,
            image: candidateImg,
        });
    };

    const handleImageClick = () => {
        if (hasImage) {
            removeImage();
        } else {
            imageInput.current.click();
        }
    };

    return (
        <div className={`${CLASS} ${className}`}>
            <input
                className={`${CLASS}-input`}
                ref={imageInput}
                type="file"
                accept="image/jpeg, image/png"
                onChange={uploadImage}
            />
            {cloneElement(children, {
                botId,
                candidateId,
                candidateImg,
                hasImage,
                isLoading,
                setIsLoading,
                handleImageClick,
            })}
        </div>
    );
};

export default ImageUpload;
