import { useState } from 'react';
import FadeIn from 'react-fade-in';

import { ImageUpload, UserAvatar } from 'ui/ImageUpload/index';
import CandidateInfoForm from 'components/Left/CandidateInfoForm/CandidateInfoForm';

import { EditIcon, PhoneIcon, EmailIcon, LocationIcon, LinkedinIcon } from 'images/index';

import './Left.scss';

const CLASS = 'jenna-left';

const Left = ({ candidateData, updateCandidate }) => {
    const [showForm, setShowForm] = useState(false);

    const { name, location, linkedin, phone, email, candidateImg, hasImage, candidateId } = candidateData;

    const lnCv = linkedin && linkedin.length && !linkedin.includes('amazonaws') ? linkedin : '';

    return (
        <FadeIn>
            <div className={CLASS}>
                {!showForm && (
                    <div className={`${CLASS}-edit-button`} onClick={() => setShowForm(true)}>
                        <EditIcon />
                    </div>
                )}
                <ImageUpload
                    candidateImg={candidateImg}
                    hasImage={hasImage}
                    candidateId={candidateId}
                    updateCandidate={updateCandidate}
                    className={`${CLASS}-user-avatar`}
                >
                    <UserAvatar />
                </ImageUpload>
                {!showForm && (
                    <div className={`${CLASS}-candidate-info`}>
                        <p className={`${CLASS}-candidate-info-name`}>{name}</p>
                        {location && (
                            <div className={`${CLASS}-candidate-info-item`}>
                                <LocationIcon />
                                <p>{location}</p>
                            </div>
                        )}
                        {email && (
                            <div className={`${CLASS}-candidate-info-item`}>
                                <EmailIcon />
                                <p>{email}</p>
                            </div>
                        )}
                        {phone && (
                            <div className={`${CLASS}-candidate-info-item`}>
                                <PhoneIcon />
                                <p>{phone}</p>
                            </div>
                        )}
                        {linkedin && (
                            <div className={`${CLASS}-candidate-info-item ${CLASS}-candidate-info-linkedin`}>
                                <LinkedinIcon />
                                <a target="_blank" href={lnCv}>
                                    {lnCv}
                                </a>
                            </div>
                        )}
                    </div>
                )}
                {showForm && <CandidateInfoForm candidateData={candidateData} setShowForm={setShowForm} />}
            </div>
        </FadeIn>
    );
};

export default Left;
