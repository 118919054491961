import { useState, useMemo } from 'react';

export const useModalsContext = () => {
    const [showTerms, setShowTerms] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);
    const [showBackButton, setShowBackButton] = useState(true);

    const setTerms = () => {
        setShowTerms(true);
        setShowPrivacy(false);
    };
    const setPrivacy = () => {
        setShowTerms(false);
        setShowPrivacy(true);
    };
    const closeTermsAndConditions = () => {
        setShowTerms(false);
        setShowPrivacy(false);
        setShowBackButton(true);
    };

    const modalContext = useMemo(
        () => ({
            showTerms,
            showPrivacy,
            showBackButton,
            setShowBackButton,
            setTerms,
            setPrivacy,
            closeTermsAndConditions,
        }),
        [showTerms, showPrivacy, showBackButton]
    );

    return modalContext;
};
