const env = process.env.NODE_ENV || 'development';

const bucketPrefix = process.env.BUCKET_PREFIX || 'jennaai';

const bucketsNames = {
  candidates: 'candidates-cvs',
  candidatesProfilePictures: 'candidates-profilepictures',
  botImages: 'web-bot-images',
  positionImages: 'position-images',
  templateCdn: 'template-cdn',
  logoImages: 'logo-images'
}

const config = {
  production: {
    candidateCVBucket: `${bucketPrefix}-${bucketsNames.candidates}`,
    candidateProfileBucket: `${bucketPrefix}-${bucketsNames.candidatesProfilePictures}`,
    botImagesBucket: `${bucketPrefix}-${bucketsNames.botImages}`,
    positionImagesBucket: `${bucketPrefix}-${bucketsNames.positionImages}`,
    templateCDNBucket: `${bucketPrefix}-${bucketsNames.templateCdn}`,
    logoImagesBucket: `${bucketPrefix}-${bucketsNames.logoImages}`,
  },
  staging: {
    candidateCVBucket: `${bucketPrefix}-${bucketsNames.candidates}-staging`,
    candidateProfileBucket: `${bucketPrefix}-${bucketsNames.candidatesProfilePictures}-staging`,
    botImagesBucket: `${bucketPrefix}-${bucketsNames.botImages}-staging`,
    positionImagesBucket: `${bucketPrefix}-${bucketsNames.positionImages}-staging`,
    templateCDNBucket: `${bucketPrefix}-${bucketsNames.templateCdn}-staging`,
    logoImagesBucket: `${bucketPrefix}-${bucketsNames.logoImages}-staging`,
  },
  development: {
    candidateCVBucket: `${bucketPrefix}-${bucketsNames.candidates}-staging`,
    candidateProfileBucket: `${bucketPrefix}-${bucketsNames.candidatesProfilePictures}-staging`,
    candidateCVBucket: `${bucketPrefix}-${bucketsNames.candidates}-staging`,
    candidateProfileBucket: `${bucketPrefix}-${bucketsNames.candidatesProfilePictures}-staging`,
    botImagesBucket: `${bucketPrefix}-${bucketsNames.botImages}-staging`,
    positionImagesBucket: `${bucketPrefix}-${bucketsNames.positionImages}-staging`,
    templateCDNBucket: `${bucketPrefix}-${bucketsNames.templateCdn}-staging`,
    logoImagesBucket: `${bucketPrefix}-${bucketsNames.logoImages}-staging`,
  }
};
const common = {
  AWSRegion: process.env.AWS_DEFAULT_REGION || 'us-east-1'
}

const awsBucket = Object.assign(config[env], common);

export default awsBucket;
