// User actions when answering interview questions
export const flowActions = {
    first_name: 'firstname',
    last_name: 'lastname',
    text: 'free text',
    short_text: 'short free text',
    cv: 'cv free text',
    linkedin: 'linkedin free text',
    phone: 'phone free text',
    email: 'email free text',
    yes_no: 'yes/no',
    quick_reply: 'quick reply',
    image_upload: 'image upload',
    image: 'image',
    next: 'next',
    skip: 'skip',
};

// User actions not related to interview questions
export const nonFlowActions = {
    policy: 'policy',
    powered_by: 'powered_by',
    open_positions: 'open_positions',
    about_position: 'about_position',
    apply: 'apply',
    about_company: 'about_company',
    continue: 'continue',
    continue_applying: 'continue_applying',
    stop: 'stop',
    stop_applying: 'stop_applying',
    yes_sub: 'yes.subscribe',
    no_sub: 'no.subscribe',
    start_pos: 'start_position',
};

export const infoActions = {
    FILE_SIZE_LARGE: 'file_size_large',
};
