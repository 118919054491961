import clsx from 'clsx';

import { useModals } from 'context';

import './Footer.scss';

const CLASS = 'jenna-footer';

const Footer = ({ inputFocus }) => {
    const { setTerms, setPrivacy, setShowBackButton } = useModals();
    return (
        <div className={clsx(CLASS, { 'footer-hidden': inputFocus })}>
            <a className={`${CLASS}-privacy`} onClick={setTerms}>
                Terms / Conditions
            </a>
            <a
                className={`${CLASS}-policy`}
                onClick={() => {
                    setPrivacy();
                    setShowBackButton(false);
                }}
            >
                Privacy Policy
            </a>
            <div className={`${CLASS}-powered-by`}>
                Powered by
                <a href="https://jenna.ai" target="_blank">
                    <div className={`${CLASS}-logo-small`}></div>
                </a>
            </div>
        </div>
    );
};

export default Footer;
