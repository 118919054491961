import FadeIn from 'react-fade-in';

import './Right.scss';

const CLASS = 'jenna-share';
const Right = ({ botId }) => {
    const urls = {
        fbShareUrl: `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
        twitterShareUrl: `https://twitter.com/intent/tweet?text=Join the team!\n&url=${window.location.href}`,
        emailUrl: `mailto:?Subject=Join the Team!&amp;&body=Join%20us!%0A%0A${window.location.href}`,
        whatsAppUrl: `https://api.whatsapp.com/send?text=Join us! \n${window.location.href}`,
        messengerUrl: `http://m.me/${botId}?ref=share`,
        inShareUrl: `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=Join%20us!&summary=Join%20Spartans&source=Jenna%20AI`,
    };

    return (
        <div className={CLASS}>
            <h5>Share ❤️ we are hiring!</h5>
            <FadeIn>
                <div className={`${CLASS}-container`}>
                    <a className={`${CLASS}-item lnkdin`} href={urls.inShareUrl} target="_blank"></a>
                    <a className={`${CLASS}-item fb`} href={urls.fbShareUrl} target="_blank"></a>
                    <a className={`${CLASS}-item tw`} href={urls.twitterShareUrl} target="_blank"></a>
                    <a className={`${CLASS}-item email`} href={urls.emailUrl} target="_blank"></a>
                    <a className={`${CLASS}-item messanger`} href={urls.messengerUrl} target="_blank"></a>
                    <a className={`${CLASS}-item whatsapp`} href={urls.whatsAppUrl} target="_blank"></a>
                </div>
            </FadeIn>
        </div>
    );
};

export default Right;
