import { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import publicIp from 'public-ip';

import socket from 'utils/socket/socket';
import usePrevious from 'utils/hooks/usePrevious';
import { ContextProvider } from 'context';
import { getCandidateId } from 'utils/utils';
import useAppEvents from 'utils/socket/useAppEvents';

import Middle from 'containers/Middle/Middle';
import Left from 'containers/Left/Left';
import Right from 'containers/Right/Right';
import TermsAndConditions from 'components/Right/TermsAndConditions/TermsAndConditions';

import avatarOne from 'images/avatar_1.svg';
import avatarTwo from 'images/avatar_2.svg';

import './App.css';

const App = () => {
    const { botId } = useParams();
    const [urlParams] = useSearchParams();
    const positionId = urlParams.get('position')?.split('?')?.[0] || '';
    const profilePlaceholderImg = Math.random() > 0.5 ? avatarOne : avatarTwo;

    const [candidateData, setCandidateData] = useState({
        positionId,
        firstName: '',
        lastName: '',
        name: '',
        privacy: '',
        email: '',
        phone: '',
        cv: '',
        linkedin: '',
        candidateImg: profilePlaceholderImg,
        hasImage: false,
        location: '',
        showLoader: false,
        candidateUid: '',
        id: getCandidateId(botId, positionId),
        companyName: '',
        botAvatar: '',
        positionSelected: '',
    });

    const previousState = usePrevious(candidateData);

    useEffect(() => {
        window.addEventListener('message', handleReceiveMsg, false);
        publicIp
            .v4({ fallbackUrls: ['https://ifconfig.co/ip'] })
            .then((ipv4) => {
                socket.emit('info', { ipv4 });
            })
            .catch(console.log);
    }, []);

    useEffect(() => {
        const { firstName, lastName } = candidateData;
        if (firstName !== previousState?.firstName || lastName !== previousState?.lastName) {
            const name = `${firstName} ${lastName}`;
            setCandidateData((prevState) => ({ ...prevState, name }));
        }
    }, [candidateData]);

    const updateCandidate = (data) => {
        const { first_name, last_name, location, _id, id, email, cv, phone, linkedin, profile_pic } = data;

        if (!id) {
            const { privacy } = candidateData;
            if (privacy === 'Accept') data.privacy = privacy;
            setCandidateData((prevState) => ({ ...prevState, ...data }));
            return;
        }

        setCandidateData((prevState) => ({
            ...prevState,
            id,
            email,
            location,
            candidateUid: _id,
            phone: phone && !phone.startsWith('+') ? `+${phone}` : phone,
            linkedin,
            cv: cv?.url ?? cv,
            firstName: first_name,
            lastName: last_name,
            candidateImg: profile_pic || profilePlaceholderImg,
            hasImage: !!profile_pic,
        }));
    };

    useAppEvents(updateCandidate, setCandidateData);

    const handleReceiveMsg = (event) => {
        if (event.origin === process.env.REACT_APP_BUBBLE_DEMO) {
            socket.emit('info', event.data);
        }
    };

    return (
        <ContextProvider>
            <div className="app">
                <div className="left">
                    <Left updateCandidate={updateCandidate} candidateData={candidateData} />
                </div>
                <div className="middle">
                    <Middle
                        botId={botId}
                        candidateImg={candidateData.candidateImg}
                        updateCandidate={updateCandidate}
                        candidateData={candidateData}
                        botAvatar={candidateData.botAvatar}
                    />
                </div>
                <div className="right">
                    <Right botId={botId} />
                </div>
                <TermsAndConditions />
            </div>
        </ContextProvider>
    );
};

export default App;
