import BotAvatar from 'components/Middle/BotAvatar/BotAvatar';
import './Image.scss';

const CLASS = 'jenna-image';

const Image = ({ imageUrl, botAvatar, shouldShowBotAvatar }) => {
    const background = {
        ...(imageUrl && { backgroundImage: `url('${imageUrl}')` }),
    };

    return (
        <div className={CLASS}>
            <BotAvatar avatar={botAvatar} shouldShow={shouldShowBotAvatar} />
            <div className={`${CLASS}-img`} style={background}></div>
        </div>
    );
};

export default Image;
