import { useState, useRef } from 'react';
import axios from 'axios';

import BotAvatar from 'components/Middle/BotAvatar/BotAvatar';
import appConfig from 'config';
import { flowActions } from 'utils/resources/qTypes';
import { Button, useSkipQuestion } from 'ui/Button';

import './CV.scss';

const CLASS = 'jenna-cv';

const MIME_TYPES = {
    word: [
        'application/msword',
        'application/doc',
        'application/ms-doc',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
        'application/vnd.oasis.opendocument.text',
    ],
    pdf: ['application/pdf'],
};

const FILE_EXTENSIONS = ['.doc', '.docx', '.odt', '.pdf'];

const validMimeTypes = MIME_TYPES.word.concat(MIME_TYPES.pdf);
const validFileExts = FILE_EXTENSIONS;

const CV = ({ isActive, shouldShowBotAvatar, candidateData, updateCandidate, message, onSendMessage, botId }) => {
    const [file, setFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSkipped, setIsSkipped] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [lastModified, setLastModified] = useState('');

    const onSkipQuestion = useSkipQuestion(onSendMessage, candidateData.id);
    const input = useRef(null);
    const { cv, botAvatar } = candidateData;
    const finalText = isSkipped ? 'CV skipped!' : 'CV uploaded!';

    const getBinaryFileType = (arrayBuffer) => {
        const uint = new Uint8Array(arrayBuffer);
        const bytes = [];
        uint.forEach((byte) => {
            bytes.push(byte.toString(16));
        });
        const signatureHex = bytes.join('').toUpperCase();

        switch (signatureHex) {
            case '25504446':
                return 'pdf';
            case '504B0304':
            case '504B34':
            case 'D0CF11E0':
                return 'word';
            default:
                return 'unknown';
        }
    };

    const setErrorState = (errorMsg) => {
        setIsUploading(false);
        setIsUploaded(false);
        setIsError(true);
        setErrorMsg(errorMsg);
    };

    const onLoadEnd = async (evt, file) => {
        if (evt.target.readyState !== FileReader.DONE) {
            return;
        }

        const binaryFileType = getBinaryFileType(evt.target.result);

        const fileSizeMB = file.size / 1024 / 1024;

        if (!validMimeTypes.includes(file.type) || binaryFileType === 'unknown') {
            errorMsg = 'File must be in PDF or WORD compatible format';
        }

        if (fileSizeMB > 5.2) {
            errorMsg = 'File size must be less than 5MB';
        }

        if (errorMsg) {
            setErrorState(errorMsg);
            return;
        }

        setFile(file);
        setErrorMsg(errorMsg);
        setIsUploading(true);
        setIsUploaded(false);
        setIsError(false);

        try {
            let response = await fileUpload(file);
            if (response.status === 200) {
                handleUploadedCV(response.data);
            } else {
                errorMsg = 'Error ocurred, try again';
                setErrorState(errorMsg);
            }
        } catch (err) {
            console.error('❌❌', err);
            errorMsg = err.message;
            setErrorState(errorMsg);
        }
    };

    const handleUploadClick = () => {
        input.current.click();
        document.getElementById('input').focus();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        const filereader = new FileReader();
        filereader.onloadend = (evt) => onLoadEnd(evt, file);
        const blob = file.slice(0, 4);
        filereader.readAsArrayBuffer(blob);
    };

    const handleUploadedCV = (cv) => {
        updateCandidate({ cv });
        onSendMessage({ action: flowActions.next, text: cv, lastModified });

        setIsUploading(false);
        setIsUploaded(true);
        setIsError(false);
        setErrorMsg('');
    };

    const fileUpload = async (file) => {
        const url = `${appConfig.REACT_APP_API_URL}/uploadCv`;
        const formData = new FormData();
        formData.append('file', file);

        setLastModified(file.lastModified);

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post(url, formData, config);
    };

    const onSkip = () => {
        onSkipQuestion();
        setIsUploading(false);
        setIsUploaded(true);
        setIsError(false);
        setIsSkipped(true);
        setErrorMsg('');
    };

    return (
        <div className={CLASS}>
            <BotAvatar avatar={botAvatar} shouldShow={shouldShowBotAvatar} />
            {(!isUploading && isUploaded) || (!isActive && cv) ? (
                <p className={`${CLASS}-button`}>{finalText}</p>
            ) : isUploading && !isUploaded ? (
                <p className={`${CLASS}-button uploading`}>Uploading CV...</p>
            ) : (
                <div className={`${CLASS}-message`}>
                    <div className={`${CLASS}-message-text`} onClick={handleUploadClick}>
                        <p>{message.message.text?.[0]}</p>
                    </div>
                    {message.allowSkip && <Button onClick={onSkip} text="Skip" />}
                </div>
            )}
            <input
                type="file"
                onChange={(event) => handleFileChange(event)}
                accept={`${validMimeTypes.join(',')},${validFileExts.join(',')}`}
                ref={input}
                disabled={!isActive}
            />
        </div>
    );
};

export default CV;
