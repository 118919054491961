import FadeIn from 'react-fade-in';

import BotTextMessage from 'components/Middle/BotTextMessage/BotTextMessage';
import { ConversationButton, ConversationButtonGroup } from 'ui/Button';
import UserTextMessage from 'components/Middle/UserTextMessage/UserTextMessage';
import OpenPositionWrapper from 'components/Middle/Positions/OpenPositionWrapper/OpenPositionWrapper';
import Image from 'components/Middle/Image/Image';
import AboutPosition from 'components/Middle/Positions/AboutPosition/AboutPosition';
import CV from 'components/Middle/CV/CV';
import { ImageUpload, UploadButton } from 'ui/ImageUpload/index';
import TermsMessage from 'components/Middle/TermsMessage/TermsMessage';
import Typing from 'components/Middle/Typing/Typing';
import Video from 'components/Middle/Video/Video';
import { flowActions, nonFlowActions } from 'utils/resources/qTypes';

const MessageList = ({ candidateData, messages, updateAccept, updateCandidate, onSendMessage, botId }) => {
    const { botAvatar } = candidateData;

    return messages.map((msg, index) => {
        const prevMsg = messages[index - 1];
        const isActive = !messages[index + 1];
        const options = msg.message?.options;

        if (msg.type === 'typing') {
            return (
                <FadeIn key={index}>
                    <Typing lastsFor={msg.message.text} key={index} />
                </FadeIn>
            );
        } else if (msg.type === 'out') {
            const shouldShowBotAvatar = prevMsg?.type === 'in' || !prevMsg?.qType.includes('text');
            if (msg.qType === flowActions.quick_reply) {
                return (
                    <FadeIn key={index}>
                        {msg.message.text && (
                            <BotTextMessage
                                botAvatar={botAvatar}
                                candidateData={candidateData}
                                shouldShowBotAvatar={shouldShowBotAvatar}
                                onSendMessage={onSendMessage}
                                message={msg.message.text}
                                msg={msg}
                                isActive={isActive}
                            />
                        )}
                        <ConversationButtonGroup isShown={isActive}>
                            {options.map((option, index) => (
                                <ConversationButton key={index} onSendMessage={onSendMessage} option={option} />
                            ))}
                        </ConversationButtonGroup>
                    </FadeIn>
                );
            } else if (msg.qType === nonFlowActions.open_positions) {
                let positions = msg.message.filter((pos) => !pos.paused);
                const pausedPos = msg.message.find((pos) => pos.paused);
                if (pausedPos) {
                    positions = [pausedPos, ...positions];
                }
                return (
                    <FadeIn key={index}>
                        <OpenPositionWrapper
                            key={index}
                            shouldShowBotAvatar={shouldShowBotAvatar}
                            candidateData={candidateData}
                            onSendMessage={onSendMessage}
                            updateCandidate={updateCandidate}
                            positions={positions}
                            isActive={isActive}
                        />
                    </FadeIn>
                );
            } else if (msg.qType === flowActions.image) {
                return (
                    <FadeIn key={index}>
                        <Image
                            imageUrl={msg.message}
                            botAvatar={botAvatar}
                            shouldShowBotAvatar={shouldShowBotAvatar}
                            onSendMessage={onSendMessage}
                            key={index}
                        />
                    </FadeIn>
                );
            } else if (msg.qType === nonFlowActions.about_company) {
                return (
                    <>
                        <FadeIn key={index}>
                            <BotTextMessage
                                botAvatar={botAvatar}
                                candidateData={candidateData}
                                shouldShowBotAvatar={shouldShowBotAvatar}
                                onSendMessage={onSendMessage}
                                message={msg.message.text}
                                msg={msg}
                                isActive={isActive}
                            />
                            {msg.message.video && <Video url={msg.message.video} />}
                            <ConversationButtonGroup isShown={isActive}>
                                {options.map((option, index) => (
                                    <ConversationButton key={index} onSendMessage={onSendMessage} option={option} />
                                ))}
                            </ConversationButtonGroup>
                        </FadeIn>
                    </>
                );
            } else if (msg.qType === nonFlowActions.about_position) {
                return (
                    <FadeIn key={index}>
                        <AboutPosition
                            botAvatar={botAvatar}
                            onSendMessage={onSendMessage}
                            key={index}
                            shouldShowBotAvatar={shouldShowBotAvatar}
                            position={msg.message}
                            isActive={isActive}
                        />
                    </FadeIn>
                );
            } else if (msg.qType === nonFlowActions.policy) {
                return (
                    <FadeIn key={index}>
                        <TermsMessage
                            botAvatar={botAvatar}
                            key={index}
                            shouldShowBotAvatar={shouldShowBotAvatar}
                            updateAccept={updateAccept}
                            isActive={isActive}
                        />
                    </FadeIn>
                );
            } else if (msg.qType === flowActions.cv) {
                return (
                    <FadeIn key={index}>
                        <CV
                            updateCandidate={updateCandidate}
                            candidateData={candidateData}
                            shouldShowBotAvatar={shouldShowBotAvatar}
                            onSendMessage={onSendMessage}
                            isActive={isActive}
                            message={msg}
                        />
                    </FadeIn>
                );
            } else if (msg.qType === flowActions.image_upload) {
                const { candidateImg, hasImage, id: candidateId } = candidateData;
                return (
                    <FadeIn key={index}>
                        <BotTextMessage
                            botAvatar={botAvatar}
                            candidateData={candidateData}
                            shouldShowBotAvatar={shouldShowBotAvatar}
                            onSendMessage={onSendMessage}
                            message={msg.message.text}
                            msg={msg}
                            isActive={isActive}
                        />
                        <ImageUpload
                            botId={botId}
                            candidateImg={candidateImg}
                            hasImage={hasImage}
                            candidateId={candidateId}
                            updateCandidate={updateCandidate}
                        >
                            <UploadButton message={msg} isActive={isActive} onSendMessage={onSendMessage} />
                        </ImageUpload>
                    </FadeIn>
                );
            } else {
                return (
                    <FadeIn key={index}>
                        <BotTextMessage
                            botAvatar={botAvatar}
                            candidateData={candidateData}
                            shouldShowBotAvatar={shouldShowBotAvatar}
                            onSendMessage={onSendMessage}
                            key={index}
                            message={msg.message.text}
                            msg={msg}
                            isActive={isActive}
                        />
                    </FadeIn>
                );
            }
        } else {
            const shouldShowBotAvatarIn = prevMsg && prevMsg.type === 'in' ? false : true;
            return (
                <UserTextMessage
                    key={index}
                    message={msg.text}
                    botId={botId}
                    shouldShowBotAvatar={shouldShowBotAvatarIn}
                    candidateData={candidateData}
                    updateCandidate={updateCandidate}
                />
            );
        }
    });
};

export default MessageList;
