import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import socket from 'utils/socket/socket';
import { ls } from 'utils/utils';

const useAppEvents = (updateCandidate, setCandidateData) => {
    const { botId } = useParams();

    const saveCandidateId = (id) => {
        let candidateIds = ls.getItem('candidateIds') || [];
        candidateIds.push(id);
        ls.setItem('candidateIds', [...new Set(candidateIds)]);
    };

    useEffect(() => {
        socket.on('startChat', ({ error, privacy, candidate, companyName, botAvatar }) => {
            if (error) {
                console.log('Error starting chat', error);
                return;
            }
            updateCandidate(candidate);
            updateCandidate({ companyName, botAvatar, privacy });
            saveCandidateId(candidate.id);
            document.title = 'Join ' + companyName + ' - Powered by Jenna AI';
        });

        socket.on('updateCandidateInfo', ({ error, status, candidate }) => {
            if (error) {
                console.log('error updating candidate', status);
                return;
            }
            updateCandidate(candidate);
        });

        socket.on('appDone', ({ appsReceived, lastAppDate }) => {
            // window.mixpanel.track('Job Application', {
            //     'Applications Received': appsReceived,
            //     'Last Application Date': lastAppDate,
            //     'Application Source': source,
            //     'Account Name': candidateData.companyName,
            //     'Job Title': candidateData.positionSelected,
            // });
            setCandidateData((prevState) => ({ ...prevState, positionSelected: '' }));

            if (botId === '608156ace045e9001593681a') window.open(`${window.location.origin}/thankYou`, '_blank');
        });

        return () => {
            socket.off('startChat');
            socket.off('updateCandidateInfo');
            socket.off('appDone');
        };
    }, []);
};

export default useAppEvents;
