import FadeIn from 'react-fade-in';

import { ImageUpload, UserAvatar } from 'ui/ImageUpload/index';

import './UserTextMessage.scss';

const CLASS = 'jenna-user-message';

const UserTextMessage = ({ message, candidateData, updateCandidate, shouldShowBotAvatar }) => {
    const { candidateImg, hasImage, candidateId } = candidateData;

    return (
        <FadeIn>
            <div className={CLASS}>
                <div className={`${CLASS}-text-container`}>
                    <p className={`${CLASS}-text`}>{message}</p>
                </div>
                {shouldShowBotAvatar && (
                    <ImageUpload
                        candidateImg={candidateImg}
                        hasImage={hasImage}
                        candidateId={candidateId}
                        updateCandidate={updateCandidate}
                        className={`${CLASS}-avatar`}
                    >
                        <UserAvatar />
                    </ImageUpload>
                )}
            </div>
        </FadeIn>
    );
};

export default UserTextMessage;
