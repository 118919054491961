import { createContext } from 'react';

import { useModalsContext } from './modals/useModalsContext';

export const AppContext = createContext({
    showTerms: false,
    showPrivacy: false,
    showBackButton: true,
    setTerms: () => {},
    setPrivacy: () => {},
    setShowBackButton: () => {},
    closeTermsAndConditions: () => {},
});

export const ContextProvider = ({ children }) => (
    <AppContext.Provider value={{ ...useModalsContext() }}>{children}</AppContext.Provider>
);
