import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import OpenPosition from '../OpenPosition/OpenPosition';
import './OpenPositionWrapper.scss';

const CLASS = 'jenna-open-positions';

const sliderSettings = {
    className: 'center',
    centerMode: false,
    infinite: false,
    centerPadding: '20px',
    slidesToShow: 2,
    variableWidth: true,
    speed: 500,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                variableWidth: true,
                infinite: false,
                centerMode: false,
            },
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                variableWidth: true,
            },
        },
    ],
};

const OpenPositionWrapper = ({ candidateData, isActive, positions, updateCandidate, onSendMessage }) => (
    <div className={`${CLASS}`}>
        <Slider {...sliderSettings}>
            {positions.map((position, index) => (
                <OpenPosition
                    key={index}
                    onSendMessage={onSendMessage}
                    position={position}
                    isActive={isActive}
                    candidateData={candidateData}
                    updateCandidate={updateCandidate}
                />
            ))}
        </Slider>
    </div>
);

export default OpenPositionWrapper;
