import React from 'react';
import ReactDOM from 'react-dom';

import Router from 'router/Router';
import * as serviceWorker from 'utils/serviceWorker';
import 'utils/language/i18n';

import './index.css';

const MOUNT_NODE = document.getElementById('App');

let render = async () => {
    ReactDOM.render(<Router />, MOUNT_NODE);
};
serviceWorker.unregister();

render();

// Enable HMR
if (process.env.NODE_ENV === 'development') {
    if (module.hot) {
        const renderApp = render;
        render = () => {
            try {
                renderApp();
            } catch (e) {
                console.error(e); // eslint-disable-line
            }
        };
        module.hot.accept(['./router/Router'], () =>
            setImmediate(() => {
                ReactDOM.unmountComponentAtNode(MOUNT_NODE);
                render();
            })
        );
    }
}
