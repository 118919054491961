import './BotAvatar.scss';

const CLASS = 'jenna-bot-avatar';

const BotAvatar = ({ avatar, shouldShow }) => {
    let style;
    if (!shouldShow) {
        style = {
            background: 'white none',
            border: '1px solid white',
        };
    } else {
        style = {
            backgroundImage: `url('${avatar}')`,
            backgroundSize: 'contain',
        };
    }
    return <div className={CLASS} style={style} />;
};

export default BotAvatar;
