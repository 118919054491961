import './Video.css';

const Video = ({ url }) => {
    const embedYoutube = (url) => {
        const pattern = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(\S+)/g;
        let result = url;

        if (pattern.test(url)) {
            const replacement = 'https://www.youtube.com/embed/$1';
            result = url.replace(pattern, replacement);
            result = result.replace('&amp;t=', '?t=');
        }

        return result;
    };

    const embedVimeo = (url) => {
        const pattern = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(\S+)/g;
        let result = url;

        if (pattern.test(url)) {
            const replacement = '//player.vimeo.com/video/$1';
            result = url.replace(pattern, replacement);
        }

        return result;
    };

    let urlType = '';
    let embededUrl = '';

    if (url?.includes('youtu')) {
        urlType = 'youtube';
        embededUrl = embedYoutube(url);
    } else if (url?.includes('vimeo')) {
        urlType = 'vimeo';
        embededUrl = embedVimeo(url);
    } else if (url?.includes('jennaai')) {
        urlType = 'uploaded';
        embededUrl = url;
    }

    return (
        <div className="video">
            {['youtube', 'vimeo'].includes(urlType) && (
                <iframe
                    width="480"
                    height="270"
                    src={embededUrl}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded video"
                />
            )}
            {urlType === 'uploaded' && (
                <video width="480" height="270" controls autoPlay>
                    <source src={embededUrl} type="video/mp4" />
                    <source src={embededUrl} type="video/ogg" />
                    <source src={embededUrl} type="video/webm" />
                </video>
            )}
        </div>
    );
};

export default Video;
