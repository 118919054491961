import awsBuckets from 'utils/awsBuckets';
import { nonFlowActions } from 'utils/resources/qTypes';

import { Button } from 'ui/Button';

import './OpenPosition.scss';

const CLASS = 'jenna-open-position';

const OpenPosition = ({ position, isActive, onSendMessage, candidateData, updateCandidate }) => {
    const { id, paused, title, desc, image } = position;

    const onError = (e) => {
        e.target.onerror = null;
        const index = Math.floor(Math.random() * 11) + 1;
        const positionImg = `https://${awsBuckets.positionImagesBucket}.s3.${awsBuckets.AWSRegion}.amazonaws.com/img${index}.png`;
        e.target.src = positionImg;
    };

    const onApply = (isActive) => {
        if (!isActive && candidateData.positionSelected) return;
        updateCandidate({ positionSelected: title });
        window.parent.postMessage({ message: 'applied', value: true }, '*');
        document.getElementById('input')?.focus();
        onSendMessage({ positionId: id, text: 'Apply Now', action: nonFlowActions.apply });
    };

    const onAbout = (isActive) => {
        if (!isActive && candidateData.positionSelected) return;
        document.getElementById('input').focus();
        onSendMessage({
            positionId: id,
            text: 'About Position',
            action: nonFlowActions.about_position,
        });
    };

    const onContinue = () => {
        onSendMessage({
            action: nonFlowActions.continue_applying,
            text: 'Continue',
        });
    };

    return (
        <div className={`${CLASS}-wrapper`}>
            <div>
                <img className={`${CLASS}-img`} alt="position-image" src={image} onError={onError} />
                <div className={`${CLASS}-content`}>
                    <p className={`${CLASS}-content-name`}>{title}</p>
                    <p className={`${CLASS}-content-desc`}>{desc}</p>
                </div>
                <div className={`${CLASS}-actions`}>
                    {paused ? (
                        <Button className={`${CLASS}-actions-continue`} onClick={onContinue} text="Continue" />
                    ) : (
                        <>
                            <Button
                                className={`${CLASS}-actions-apply`}
                                onClick={() => onApply(isActive)}
                                text="Apply Now"
                            />
                            <Button
                                className={`${CLASS}-actions-about`}
                                onClick={() => onAbout(isActive)}
                                text="About Position"
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OpenPosition;
