export const ls = {
    getItem(name) {
        try {
            if (!name) {
                return;
            }
            return JSON.parse(localStorage.getItem(name));
        } catch (err) {
            console.log('Error in getItem method of Storage class:\n', err);
            return null;
        }
    },
    setItem(name, value) {
        try {
            if (!name) {
                return;
            }
            if (!value) {
                localStorage.removeItem(name);
                return;
            }
            localStorage.setItem(name, JSON.stringify(value));
        } catch (err) {
            console.log('Error in setItem method of Storage class:\n', err);
        }
    },
    removeItem(name) {
        localStorage.removeItem(name);
    },
    clear() {
        localStorage.clear();
    },
};

export const getCandidateId = (botId, positionId) => {
    if (!botId) {
        console.log('Provide botId to find candidate id');
        return;
    }
    const candidateIds = ls.getItem('candidateIds');
    if (!candidateIds) {
        return null;
    }
    const candidateId = candidateIds.find((id) => {
        const [_, candidateBotId, candidatePosId = ''] = id.split('.');
        if (candidateBotId === botId && candidatePosId === positionId) {
            return id;
        }
        return null;
    });
    return candidateId;
};
