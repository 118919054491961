import { ConversationButtonGroup, Button } from 'ui/Button';
import BotAvatar from 'components/Middle/BotAvatar/BotAvatar';

import './AboutPosition.scss';

const CLASS = 'jenna-about-position';

const AboutPosition = ({ onSendMessage, position, isActive, botAvatar, shouldShowBotAvatar }) => {
    const { id, description, name, options } = position;

    const handleClick = (option, isActive) => {
        if (!isActive) return;
        onSendMessage({ positionId: id, text: 'Apply Now', action: option.action });
        document.getElementById('input').focus();
    };

    return (
        <>
            <div className={CLASS}>
                <BotAvatar avatar={botAvatar} shouldShow={shouldShowBotAvatar} />
                <div className={`${CLASS}-description`}>
                    <span className={`${CLASS}-description-name`}>{name}</span>
                    <div className={`${CLASS}-description-text`}>{description}</div>
                </div>
            </div>
            <ConversationButtonGroup>
                {options.map((option, index) => (
                    <Button key={index} onClick={() => handleClick(option, isActive)} text={option.text} />
                ))}
            </ConversationButtonGroup>
        </>
    );
};

export default AboutPosition;
