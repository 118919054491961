const CLASS = 'jenna-terms-and-conditions';

const PrivacyPolicy = ({ showPrivacy, showBackButton, setTerms, closeTermsAndConditions }) => (
    <div className={`${CLASS} ${showPrivacy && `${CLASS}-show`}`}>
        {showBackButton && <div className={`${CLASS}-back`} onClick={setTerms}></div>}
        <div className={`${CLASS}-close`} onClick={closeTermsAndConditions}></div>
        <h3 className={`${CLASS}-title`}>Privacy policy</h3>
        <h4 className={`${CLASS}-titles`}>Introduction</h4>
        <p className={`${CLASS}-text`}>
            We, at Spartans AI Ltd. and its affiliates, (“JENNA” “we,” or “us”) have created this privacy policy
            (“Privacy Policy”) in order to describe the practices that Jenna follows with respect to the collection, use
            and disclosure of information collected from our online users, job applicants and clients (collectively,
            “User” or “you“).
        </p>
        <p className={`${CLASS}-text`}>
            This Privacy Policy describes how we maintain your privacy and protect the confidentiality of the
            information which we collect when you use our JENNA platform (the “Platform”), the website [URL] (the
            “Website”) and which you may share with us directly or via other online platforms (collectively,
            “Services”).
        </p>
        <p className={`${CLASS}-text`}>
            <strong>
                We encourage you to read the Privacy Policy carefully and use it to make informed decisions. By using
                the Services you agree to the terms of this Privacy Policy and your continued use of the Services
                constitutes your ongoing agreement to this Privacy Policy.
            </strong>
        </p>
        <p className={`${CLASS}-text`}>In this Privacy Policy, you can read about:</p>
        <ul className={`${CLASS}-list`}>
            <li>1. What information do we collect? </li>
            <li>2. How do we use the information? </li>
            <li>3. How do we share the information? </li>
            <li>4. What are a users’ personal rights? </li>
            <li>5. What kind of Security is maintained? </li>
            <li>6. Is there any international transfer of data?</li>
            <li>7. Are any external platforms Used?</li>
            {/* <li>8. Marketing </li> */}
            <li>8. What Notifications are Being Provided?</li>
            <li>9. Changes to privacy policy</li>
            <li>10. How to contact us </li>
        </ul>
        <h4 className={`${CLASS}-titles`}>1. What information do we collect?</h4>
        <p className={`${CLASS}-text`}>JENNA collects the following types of information from its Users: </p>
        <p className={`${CLASS}-text`}>
            <strong>
                {' '}
                <span className={`${CLASS}-underline`}>Personal information:</span>
            </strong>
            &nbsp;We may collect individually identifiable information which is related to Users, namely information
            that identifies an individual or may with reasonable effort identify an individual (“Personal Information”),
            including the following:
        </p>
        <ul className={`${CLASS}-list`}>
            <li>
                <strong>1. Data submitted when registering to our Service: </strong>
                We collect information you provide directly to us. For example, if you want to review or submit a job
                application to open positions through our service .The types of personal information we may collect from
                you through our Service includes but is not limited to your basic user information and contact
                information such as your name, address or IP location, telephone number, fax number, email address, your
                skill or trade, photograph, feedback and any other personal information you choose to provide. We may
                also collect other information about your employment history and professional experience and any other
                information required to provide you our Services.
            </li>
            <li>
                <strong>2. Voluntary information: </strong>
                We also collect information which you provide us voluntarily. We collect your information when you apply
                for a job through our Services, respond to communications from us, contact our support, communicate with
                us via email, the JENNA platform and through any online platform. Please note that when you apply for a
                job through our Services, you may be required to share with us your CV and other required information in
                order for the relevant employer to consider your application.
            </li>
            <li>
                <strong>3. Device Information: </strong>
                We may also collect Personal Information from your device, such as geolocation data, IP address and
                other online identifiers (i.e. online data collected from User’s devices, applications and protocols
                which leave traces which may be used to identify Users).
            </li>
        </ul>
        <p className={`${CLASS}-text`}>
            It should be made clear that you have no legal obligation to provide us with any Personal Information and
            the submission of such information is entirely subject to your sole discretion and consent. However, if you
            will not provide us with the required information we may not be able to provide you with the
            information/services requested by you.
        </p>
        <p className={`${CLASS}-text`}>
            <strong>
                {' '}
                <span className={`${CLASS}-underline`}>Other Information</span>
            </strong>
            &nbsp;We may collect additional information which is non-identifiable information and when taken alone,
            cannot be used to identify or contact our Users (collectively, the “Other Information”). As such, we are not
            aware of the identity of the User from which the Other Information was collected. Such Other Information may
            include:
        </p>
        <ul className={`${CLASS}-list`}>
            <li>
                <strong>1. Your Activity: </strong>
                Information that we or our technology providers may automatically collect when you visit, access, and/or
                use the Service, such as Internet service provider, domain server, type of computer, browser type and
                language, referring and exit pages and URLs, date and time, amount of time spent on particular pages,
                what sections/features of our Service that you visit/utilize, number of links you click while on the
                Service, search terms, and operating system. We may also collect statistical and other data related to
                your use of our Services as well as information on your online usage patterns. This information is
                collected and used as non-individually identifiable information.
            </li>
            <li>
                <strong>2. Cookies and JavaScript Tags: </strong>
                We may use session identifiers, web beacons, cookies, JavaScript tags, and other tracking technologies.
                A cookie is a piece of data stored on a User’s hard drive containing information about the User. We use
                these technologies to enhance the User experience of our Services, and as a result, these technologies
                may be deployed by JENNA in any portion of the Services. Usually an Internet browser is set by default
                to accept cookies. If a User rejects the cookie, he/she may still use our Services, though certain
                features, tools and applications may be disabled or otherwise unavailable. We may also use cookies to
                track and monitor usage of the Services by Users for the marketing and quality improvement purposes.
                Most browsers will allow you to erase cookies from your computer/device hard drive, block acceptance of
                cookies, or receive a warning before a cookie is stored.
            </li>
            <li>
                We may use JavaScript tags to trigger a sequence of events that includes viewing a first-party cookie
                (or setting that cookie if it does not already exist) and to help us tailor and optimize our Services
                for our Users and clients.
            </li>
            <li>
                You may remove cookies and JavaScript tags by following the instructions of your browser preferences.
            </li>
            <li>
                In addition to the foregoing, JENNA reserves the right to use the Google AdWords Remarketing Service
                (the “Google Service”) for advertising purposes. By using the Google Service, visitors of those sites,
                apps and other digital properties belonging to JENNA’s clients and partners may see advertisements in
                third parties’ websites (including, without limitation on Google’s search results page or a site in the
                Google Display Network). Any data collected by JENNA using the Google Service will be subject to both
                this Privacy Policy as well as Google’s &nbsp;
                <a href="https://policies.google.com/privacy" target="_blank">
                    Privacy Policy
                </a>
                .
            </li>
            <li>
                <strong>3. Social Media Widgets: </strong>
                Our Services includes Social Media features, such as the Facebook Like button. These features may
                collect which page you are visiting on our site, and may set a cookie to enable the feature to function
                properly. Social Media features and Widgets are either hosted by a third party or hosted directly on our
                Service. Your interactions with these features are governed by the privacy policy of the company
                providing it.
            </li>
            <li>
                <strong>4. Location Information: </strong>
                We may collect information about the location of your device each time you access or use one of our
                mobile applications or otherwise consent to the collection of this information. For more details, please
                see “Your Choices” below.
            </li>
        </ul>
        <h4 className={`${CLASS}-titles`}>2. How do we use information</h4>
        <p className={`${CLASS}-text`}>
            In addition to the purposes listed above, the information we collect, which may include your Personal
            Information, is used for the following purposes:
        </p>
        <ul className={`${CLASS}-list`}>
            <li>1. To provide you our Services </li>
            <li>2. Analysis and process of the application data to identify candidate – employer compatibility </li>
            <li>3. To process your job application to the relevant enterprise</li>
            <li>
                4. To communicate with you and to keep you informed of our latest updates and provide you with
                notifications
            </li>
            <li>
                5. To perform a research or to conduct analysis to improve and customize our Services to your needs and
                interests{' '}
            </li>
            <li>
                6. To process any of your requests regarding User’s rights (e.g. right to access, right to be forgotten,
                etc.){' '}
            </li>
            <li>7. To support and troubleshoot our Services and to respond to your queries</li>
            <li>
                8. To investigate violations and enforce our policies, and as required by law, regulation or other
                governmental authority, or to comply with a subpoena or similar legal process or respond to a government
                request.
            </li>
        </ul>
        <h4 className={`${CLASS}-titles`}>3. How we share the information</h4>
        <p className={`${CLASS}-text`}>
            We do not share, distribute, sell or rent any of your Personal Information with third parties, except in the
            following circumstances:
        </p>
        <ul className={`${CLASS}-list`}>
            <li>
                1. Through our platform, enterprise users may seek employees or consultants. Upon your submission of an
                application, including but not limited to your CV and answers to applicant questions, these users will
                have access to your application, including any ratings or reviews the application. We may also share
                aggregated or de-identified information without restriction, including for analytics, business
                intelligence and benchmarking purposes, provided such data cannot be re-identified.
            </li>
            <li>
                2. If the information is required by law in order to prevent, investigate, or take action regarding
                illegal activities. In addition, we will share information in response to legal process, court orders,
                subpoenas, or to establish or exercise our legal rights or defend against legal claims
            </li>
            <li>
                3. We may also disclose the Information to any government or law enforcement authority or regulatory
                agency in response to lawful requests by applicable local law enforcement agencies or regulatory
                agencies, or agencies with responsibility to oversee and enforce national security
            </li>
            <li>
                4. We may share some or all of your Information with any of our parent companies, subsidiaries, joint
                ventures, or other companies under common control with us
            </li>
            <li>
                5. Information collected from Users of the Services may be transferred as part of or in connection with
                a corporate merger, consolidation, restructuring, the sale of substantially all of our shares or stock
                and/or assets or other corporate change, including, during the course of any due diligence process.
                <strong>
                    By making available your data through the Services in any manner, you agree that your information
                    may be transferred to third parties under such or similar circumstances.
                </strong>
            </li>
            <li>
                6. For the purpose of providing and operating the Services we may share Personal Information with
                trusted third-party service providers that require access to such data for purpose of providing
                Services-related services to us only (e.g. storing information on our behalf, processing information to
                assist us with our business operations, processing your payments; performing research, technical
                diagnostics or analytics etc.)
            </li>
            <li>7. If we have permission to do so.</li>
        </ul>
        <h4 className={`${CLASS}-titles`}>4. What are a users’ personal rights? </h4>
        <p className={`${CLASS}-text`}>
            You may have some or all of the following rights in respect of your personal information:
        </p>
        <ul className={`${CLASS}-list`}>
            <li>
                To access and obtain a copy of your personal information in our control or possession, and information
                about how and on what basis this personal information is processed by us.
            </li>
            <li>
                You may update, correct or delete information about you at any time by logging into your online account,
                emailing us at support@jenna.ai. We encourage you to update or correct your information if your details
                change, or if you think that the information we hold about you is incorrect, inaccurate or out of date.
            </li>
            <li>
                To request that we restrict processing of your personal information where: (i) the accuracy of the
                personal information is contested, (ii) the processing is unlawful but you object to your personal
                information being erased, (iii) where it is no longer needed for the purposes for which it was
                collected, but we still need it to establish, exercise or defend legal claims, or (iv) you have
                exercised the right to object, and verification of overriding grounds is pending. You may also have the
                right to challenge processing which we have justified on the basis of a legitimate interest.
            </li>
            <li>
                To ask us to provide your personal information to you in a structured, commonly used, machine-readable
                format, or you can ask to have it 'ported' directly to another data controller, but in each case only
                where, the processing is based on your consent or on the performance of a contract with you; and the
                processing is carried out by automated means.
            </li>
            <li>
                If you wish to deactivate your account, or where processing is based on consent, to withdraw your
                consent to our collection, use and/or disclosure of your personal information at any time, please email
                us at contact+delete@jenna.ai, but note that we may retain certain information as required by law or for
                legitimate business purposes. We may also retain cached or archived copies of information about you for
                a certain period of time in accordance with applicable laws and regulations
            </li>
            <li>
                In limited circumstances, you may request that we erase your personal information, for example, where it
                is no longer necessary in relation to the purposes for which it was collected or processed.
            </li>
        </ul>
        <p className={`${CLASS}-text`}>
            If you have not interacted in any manner with the Service for a period exceeding eight (8) months, we will
            automatically anonymize and remove any Personal Information provided by you in connection with the Service,
            unless such information is maintained: (i) due to an applicable law, regulation or policy; (ii) in order to
            prevent, investigate, or take action regarding illegal activities; or (iii) as otherwise required as part of
            a legal proceeding.
        </p>
        <h4 className={`${CLASS}-titles`}>5. What kind of Security is maintained?</h4>
        <p className={`${CLASS}-text`}>
            We follow generally accepted industry standards to protect the Information from loss, misuse, and
            unauthorized access, disclosure, alteration, or destruction. However, due to the nature of Internet
            communications and evolving technologies, unauthorized entry or use, hardware or software failure, and other
            factors, the security of User information may be compromised at any time. Therefore, we cannot guarantee the
            absolute security of the Information d disclaim any assurance that such information will remain free from
            loss, misuse, or alteration by third parties who, despite our efforts, obtain unauthorized access.
        </p>
        <h4 className={`${CLASS}-titles`}>6. Is There any International Transfer of Data?</h4>
        <p className={`${CLASS}-text`}>
            In the process of making available the Services to its Users, JENNA may transfer information that it
            collects to affiliated entities or to other third parties across borders and from your country or
            jurisdiction to other countries or jurisdictions around the world. By using the Services, you consent to the
            transfer of your information to the United States and elsewhere, including outside of the EEA.
        </p>
        <p className={`${CLASS}-text`}>
            If you are located in the EEA, your personal information will only be transferred to locations outside of
            the EEA where we are satisfied that adequate or comparable levels of protection are in place to protect
            personal information. We will take reasonable steps to ensure that: (i) internal transfers will be covered
            by an intra-group agreement which gives specific contractual protections designed to ensure that your
            personal information receives an adequate and consistent level of protection when it is transferred between
            our affiliates; and (ii) we have appropriate contractual arrangements with our third parties to ensure
            adequate safeguards are in place such that the risks of your personal information being unlawfully used,
            amended, deleted, lost or stolen are minimised, and that such third parties will act at all times in
            compliance with applicable personal information protection laws. You may have a right to details of the
            mechanism under which your data is transferred outside of the EEA.
        </p>
        <h4 className={`${CLASS}-titles`}>7. Are any External Platforms Used?</h4>
        <p className={`${CLASS}-text`}>
            The Service may contain links to third-party websites and platforms. JENNA has no control over the privacy
            practices or the content of these websites or platforms. As such, we are not responsible for the content or
            the privacy policies of those third-party websites or platforms. You should check the applicable third-party
            privacy policy and terms of use when visiting any other websites or platforms.
        </p>
        <h4 className={`${CLASS}-titles`}>8. What Notifications are Being Provided?</h4>
        <p className={`${CLASS}-text`}>
            Subject to your opting in via your Social Network Account, from time to time, we would like to send you
            information regarding Job Openings that we think may be of interest to you. You may opt out of receiving
            such communications f rom us by following the instructions in those communications or by emailing
            contact@jenna.ai . If you opt out of these communications, we may still send you non-promotional
            communications, unless you have also withdrawn your co nsent more generally to our collection, use and/or
            disclosure of your personal information.
        </p>
        <h4 className={`${CLASS}-titles`}>9. Changes to This Privacy Policy</h4>
        <p className={`${CLASS}-text`}>
            This Privacy Policy is effective as of the date stated at the top of this Privacy Policy. We may change this
            Privacy Policy from time to time. By accessing and/or using the after we make any such changes to this
            Privacy Policy, you are deemed to have accepted such changes. Please be aware that, to the extent permitted
            by applicable law, our use of the Information is governed by the Privacy Policy in effect at the time we
            collect the Information. Please refer back to this Privacy Policy on a regular basis.
        </p>
        <h4 className={`${CLASS}-titles`}>10. How to Contact Us</h4>
        <p className={`${CLASS}-text`}>
            To make any requests as outlined above or if you have any questions about this Privacy Policy or for more
            information, please contact us at: contact@jenna.ai or at Menachem Begin 121, Tel-Aviv, Israel. You also
            have the right to lodge a complaint with your local supervisory authority, in respect of our collection, use
            or disclosure of your personal information.
        </p>
        <p className={`${CLASS}-text`}>
            Please note, we will not ask for a fee to exercise any of your rights in relation to your personal
            information, unless your request for access to information is unfounded, respective or excessive, in which
            case we will charge a reasonable amount in the circumstances
        </p>
    </div>
);

export default PrivacyPolicy;
