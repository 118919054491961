import { useModals } from 'context';
import TermsOfService from './resources/TermsOfService';
import PrivacyPolicy from './resources/PrivacyPolicy';

import './TermsAndConditions.scss';

const TermsAndConditions = () => {
    const { showTerms, showPrivacy, showBackButton, setTerms, setPrivacy, closeTermsAndConditions } = useModals();

    return (
        <>
            {(showTerms || showPrivacy) && <div className="jenna-overlay" onClick={closeTermsAndConditions}></div>}
            <TermsOfService
                showTerms={showTerms}
                setPrivacy={setPrivacy}
                closeTermsAndConditions={closeTermsAndConditions}
            />
            <PrivacyPolicy
                showPrivacy={showPrivacy}
                setTerms={setTerms}
                closeTermsAndConditions={closeTermsAndConditions}
                showBackButton={showBackButton}
            />
        </>
    );
};

export default TermsAndConditions;
