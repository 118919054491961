import { useContext } from 'react';

import { AppContext } from 'context/Context';

export const useModals = () => {
    const { showTerms, showPrivacy, showBackButton, setTerms, setPrivacy, setShowBackButton, closeTermsAndConditions } =
        useContext(AppContext);

    return { showTerms, showPrivacy, showBackButton, setTerms, setPrivacy, setShowBackButton, closeTermsAndConditions };
};
