import { ReactComponent as ThankYouSvg } from 'images/thank-you-note-jenna.svg';
import './ThankYou.css';

const ThankYou = () => {
    return (
        <div className="thankYou-wrapper">
            <img
                height="1"
                width="1"
                border="0"
                src={`//conv.indeed.com/pagead/conv/9798056664303701/?script=0&rand=${new Date().getTime()}`}
            />
            <ThankYouSvg />
            <p>Your application was recorded successfully!</p>
            <p>Thank you for chatting with Jenna.</p>
        </div>
    );
};

export default ThankYou;
