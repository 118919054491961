const CLASS = 'jenna-terms-and-conditions';

const TermsOfService = ({ showTerms, setPrivacy, closeTermsAndConditions }) => (
    <div className={`${CLASS} ${showTerms && `${CLASS}-show`}`}>
        <div className={`${CLASS}-close`} onClick={closeTermsAndConditions}></div>
        <h3 className={`${CLASS}-title`}>Terms of service</h3>
        <h4 className={`${CLASS}-titles`}>Introduction</h4>
        <p className={`${CLASS}-text`}>
            These Terms and Conditions (the " Terms ") you are reading is a legal agreement between Jenna AI Inc. ("
            Jenna ", " we ," us ," and " our ") and you. By accessing or using this service, or any content, feature,
            product or application made available, from time to time, on this website or through the JENNA Platform,
            through a mobile, tablet or other device (" Platform ") ( collectively, the " Services ") you agree that you
            have read, understood and agree to be bound by these Terms. If you do not agree to these Terms, do not use
            any of the Services provided by Jenna.
        </p>
        <p className={`${CLASS}-text`}>
            PLEASE READ THESE TERMS CAREFULLY AND IN ITS ENTIRETY BEFORE USING THE SERVICES. BY USING OR OTHERWISE
            ACCESSING OUR SERVICES, YOU AGREE TO THESE TERMS. WE ARE NOT RECRUITMENT, EMPLOYMENT OR HUMAN RESOURCE
            ADVISORS. THE SERVICES AND THE CONTENT PROVIDED IN CONNECTION THEREIN ARE NOT AND SHOULD NOT BE CONSTRUED AS
            EMPLOYMENT ADVICE OR A RECOMMENDATION OR OPINION WITH RESPECT TO ANY PARTICULAR JOB, CAREER, EMPLOYER OR
            EMPLOYEE. CONTENT PUBLISHED IN CONNECTION WITH THE SERVICES OR PLATFORM, DOES NOT CONSTITUTE AN OFFER FOR
            EMPLOYMENT, BUT RATHER A GENERAL PRESENTATION OF THE POSSIBILITY TO APPLY FOR A POSITION. IN ADDITION, THE
            SERVICES AND THE CONTENT PROVIDED IN CONNECTION THEREWITH MAY CONTAIN, BE BASED ON OR OTHERWISE REFER TO
            ERRORS, INACCURACIES, PARTIAL OR INCOMPLETE INFORMATION, OUTDATED DATA, MISTAKES OR FAULTS.
        </p>
        <p className={`${CLASS}-text`}>
            These Terms together with our Privacy Policy, which may be found here –{' '}
            <span onClick={setPrivacy} className={`${CLASS}-link`}>
                Privacy Policy
            </span>
            , and any additional terms which might apply to any Services offered, from time to time, by or through the
            Platform, govern your use of the Platform and any Services provided or made available through the Platform.
        </p>
        <h4 className={`${CLASS}-titles`}>1. Service</h4>
        <p className={`${CLASS}-text`}>
            Jenna is a SaaS platform that optimizes and streamlines the initial screening process helping companies and
            organizations to screen candidates faster and smarter. Through the Jenna interface, potential candidates can
            easily receive information about open positions, the hiring company and apply to open positions (“{' '}
            <strong>Job Openings</strong> ”).
        </p>
        <p className={`${CLASS}-text`}>
            Subject to Your full compliance with all of the terms herein, We hereby grant You a non-exclusive,
            revocable, non-sublicensable, non-transferable worldwide and limited right and license to access and use the
            Services solely for the purpose of applying for Job Openings ("License"). We reserve the right, without any
            liability, to (i) change the technical features of the Services in order to keep pace with the latest
            demands and technological developments; (ii) to discontinue, limit, disable or cancel any feature of the
            Service; or (iii) to terminate the License in accordance with the terms set forth herein.
        </p>
        <h4 className={`${CLASS}-titles`}>2. User Information</h4>
        <p className={`${CLASS}-text`}>
            The Service is available only to individuals who are at least 18 years old (and at least the legal age in
            your jurisdiction). You represent and warrant that if you are an individual, you are at least 18 years old
            and of legal age in your jurisdiction to form a binding contract, and that all any information you submit is
            accurate and truthful. Jenna may, in its sole discretion, refuse to offer the Service to any person or
            entity. The Service is not available to any Users suspended or removed from the Platform or Service by
            Jenna. By using the Service, you represent that you have not been previously suspended or removed from the
            Platform or Service
        </p>
        <p className={`${CLASS}-text`}>
            The Services made available on the Platform may require you to provide current, complete and accurate
            information as prompted by the applicable candidate screening questions Furthermore, you are entirely r
            esponsible for all activities that occur in connection with your interaction with the Service, including but
            not limited to any answers given during the candidate screening process. You agree to notify Jenna
            immediately of any unauthorized interaction with the Services by a third party or any other breach of
            security.
        </p>
        <p className={`${CLASS}-text`}>
            You can access the Service through a dedicated web page or by using la third party platform such as Facebook
            (the " <strong>Social Network Account</strong> "). If You register via a third party platform account you
            hereby grant us access to certain information about you which is stored in the Social Network Account.
        </p>
        <h4 className={`${CLASS}-titles`}>3. Limitation, Suspension or Termination</h4>
        <p className={`${CLASS}-text`}>
            a) We may, in our sole discretion, temporarily or permanently change, limit, suspend or terminate your
            access to the Platform or any Services (including any Job Opening) without prior notice. You agree that we
            are not liable to you or any third party for any such action.
        </p>
        <p className={`${CLASS}-text`}>
            b) Any limitation, suspension, or termination we may impose shall not alter your obligations to us under
            these Terms. The provisions of these Terms, which by their nature should survive any such action on our
            part, shall survive including, but not limited to, provisions relating to indemnities, releases,
            disclaimers, limitations on liability, and the miscellaneous provisions below. Upon termination of these
            Terms, or in any case determined by us in our sole discretion, your license to use the Service will
            immediately expire.
        </p>
        <h4 className={`${CLASS}-titles`}>4. Acceptable Use</h4>
        <p className={`${CLASS}-text`}>
            a) Our Services are provided for your information and personal, non-commercial use only. When using our
            Services, you must comply with these Terms, and all applicable laws.
        </p>
        <p className={`${CLASS}-text`}>b) Except as expressly permitted by these Terms, you may not:</p>
        <ul className={`${CLASS}-list`}>
            <li>
                1) Use our Services in an unlawful or fraudulent manner or for such purposes, to collect personally
                identifiable information, or to impersonate other users
            </li>
            <li>
                2) Modify or use our copyright, trademark or other proprietary rights notices, or interfere with the
                security-related features of our Services
            </li>
            <li>
                3) Use our Services in any way to manipulate or distort, or undermine the integrity and accuracy of, any
                content, or take any action to interfere with, damage, disrupt any part of our Services;rmation about
                how and on what basis this personal information is processed by us
            </li>
            <li>
                4) Use our Services to send, receive, upload/post, download, any material which does not comply with our
                content standards
            </li>
            <li>
                5) Use our Services to transmit or facilitate the transmission of any unsolicited or unauthorized
                advertising or promotional material
            </li>
            <li>
                6) Usee our Services to transmit any data, or upload to our Services any data, that contains viruses,
                Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or
                similar computer code designed to adversely affect the operation of any computer software or hardware
            </li>
            <li>7) Decompile, reverse engineer or disassemble any portion of our Services</li>
            <li>
                8) Use any robot, spider, other automatic device or manual process to monitor or copy our web pages or
                the content contained our Services, or use network-monitoring software to determine architecture of or
                extract usage data from our Services;
            </li>
            <li>
                9) Engage in any conduct that restricts or inhibits any other user from using or enjoying our Services
            </li>
            <li>
                10) x) use our Services for any commercial purpose or in connection with any commercial activity
                performed, without obtaining our prior written consent or
            </li>
        </ul>
        <p className={`${CLASS}-text`}>
            You agree to fully cooperate with us to investigate any suspected or actual activity that is in breach of
            these Terms and Conditions.
        </p>
        <h4 className={`${CLASS}-titles`}>5. Intellectual Property</h4>
        <p className={`${CLASS}-text`}>
            Our Platform, Services and related content (and any derivative works or enhancements of the same) including,
            but not limited to, all text, illustrations, files, images, software, scripts, graphics, photos, sounds,
            music, videos, information, content, materials, products, services, URLs, technology, documentation and
            interactive features (collectively, " Content ") and all intellectual property rights to the same are owned
            by us and/or by third parties, and nothing herein grants you any right in connection with the Content.
            Additionally, all trademarks, service marks, trade names and trade dress that may appear on our Platform or
            Services are owned by us, our licensors, or both. Except as expressly set forth herein, you shall not
            acquire any right, title or interest in our Platform or Services. Any rights not expressly granted in these
            Terms are expressly reserved.
        </p>
        <p className={`${CLASS}-text`}>
            You represent and warrant that any input, text, content or feedback provided by you in connection with the
            Services, including but not limited to any response to any candidate screening question and your CV (“ Input
            ”), shall not infringe upon or cause the infringement of any third party intellectual property, privacy or
            any other similar right. You further acknowledge that any and all rights, including intellectual property
            rights in such Input shall belong exclusively to Jenna and shall be considered Jenna’s Confidential
            Information. It is further understood that use of Input, may be made by Jenna in its sole discretion,
            subject to the privacy policy.
        </p>
        <h4 className={`${CLASS}-titles`}>6. Services Components </h4>
        <p className={`${CLASS}-text`}>
            We retain the right, at our sole discretion, to implement limits to the nature of, or your continued ability
            to access the Services and any data, and impose other limitations at any time, with or without notice,
            including without limitation, by charging fees. You acknowledge that a variety of our actions may impair or
            prevent you from accessing or using the Services at certain times and/or in the same way, for limited
            periods or permanently, and agree that we have no responsibility or liability as a result of any such
            actions or results, including, without limitation, for the deletion of, or failure to make available to you,
            the Services. We may from time to time engage certain affiliates or other third parties to provide all or
            part of the Services to you, and you hereby acknowledge and agree that such third party involvement is
            acceptable. e may from time to time include as part of the Services computer software supplied by third
            parties which is utilized by permission of the respective licensors and/or copyright holders on the terms
            provided by such parties. We expressly disclaim any warranty or other assurance to you regarding such third
            party software.
        </p>
        <h4 className={`${CLASS}-titles`}>7. Modifications</h4>
        <p className={`${CLASS}-text`}>
            We reserve the right at any time and at our sole discretion to modify or discontinue, temporarily or
            permanently, the Services or any part thereof (including but not limited to Content), with or without
            notice. You agree that we shall not be liable to you or to any third party for any modification, suspension
            or discontinuance of any part of the Services.
        </p>
        <h4 className={`${CLASS}-titles`}>8. Disclamers</h4>
        <p className={`${CLASS}-text`}>
            THE SERVICES, INCLUDING THE PLATFORM AND ALL INFORMATION, MATERIALS, ASSESSMENT AND CONTENT PROVIDED IN
            CONNECTION THEREWITH ARE PROVIDED "AS IS" AND "AS AVAILABLE", WITHOUT ANY WARRANTIES OF ANY KIND, EITHER
            EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE OR ANY WARRANTIES REGARDING
            THE SECURITY, RELIABILITY, TIMELINESS, ACCURACY AND PERFORMANCE OF OUR SERVICES TO THE MAXIMUM EXTENT
            PERMITTED BY APPLICABLE LAW. WE DO NOT WARRANT THAT THE PLATFORM OR SERVICES WILL BE PROVIDED UNINTERRUPTED,
            ERROR FREE OR THAT IT SHALL MEET YOUR REQUIREMENTS
        </p>
        <p className={`${CLASS}-text`}>
            The Services may contain, or direct you to other web pages. We make no representations regarding any content
            contained in or accessed through the Services, and we will not be responsible or liable for the accuracy,
            copyright compliance, legality or decency of material contained in or accessed through the Services.
        </p>
        <p className={`${CLASS}-text`}>
            You understand that (i) no content published in connection with the Services, constitutes a recommendation
            or opinion with respect to any particular enterprise, Job Opening, employee, employer, career opportunity or
            its suitability for any specific person; (ii) Jenna makes no, and, to the fullest extent permitted under
            law, hereby disclaims all, representations or warranties of any kind, express or implied, as to the
            availability, operation and use of the Service or the information, content, materials or services on or
            accessed via the Platform, including but not limited to warranties of merchantability, fitness for a
            particular purpose, title, non-infringement; (iii) we may provide rankings of an applicant's suitability for
            a Job Opening based on an algorithm which is a subjective algorithm chosen by Us, in our sole discretion;
            (iv) our ranking depends on the information you provide through the Service and public information, which
            may be inaccurate, wrong, outdated, partial or incomplete; (v) our ranking system may be subject to errors
            and interruptions; (vi) the results and rankings provided by Us may be wrong, inaccurate, outdated, partial
            or incomplete or do not fit for a particular purpose and do not take into account any of your or the
            enterprises employment objectives, financial situation, or means.
        </p>
        <p className={`${CLASS}-text`}>
            THE SERVICES ARE OFFERED SOLELY FOR YOUR INFORMATION AND NOT FOR ANY COMMERCIAL USE. YOU SHOULD NOT RELY
            UPON ANY INFORMATION OR CONTENT INCLUDED IN THE SERVICES FOR PURPOSES OF ANY CAREER OR EMPLOYMENT DECISION
            AND YOU ARE ENCOURAGED TO CONDUCT YOUR OWN RESEARCH AND DUE DILIGENCE.
        </p>
        <p className={`${CLASS}-text`}>
            You are solely responsible for any actions or decisions you take based on materials and information
            available through our Services, and should carry out your own research and investigation as appropriate.
        </p>
        <p className={`${CLASS}-text`}>
            As our Services are web based and require access to the internet any unavailability caused by circumstances
            beyond Our reasonable control, including without limitation, acts of God, acts of government, Internet
            service provider failures or delays, or denial of service attacks may occur.
        </p>
        <h4 className={`${CLASS}-titles`}>9. Modifications</h4>
        <p className={`${CLASS}-text`}>
            UNDER NO CIRCUMSTANCES SHALL JENNA OR ITS OFFICERS, DIRECTORS, EMPLOYEES, PARTNERS, SUCCESSORS, AGENTS,
            AFFILIATES, SUBSIDIARIES OR RELATED COMPANIES OR ANY THIRD PARTY WORKING WITH THE JENNA BE LIABLE FOR
            INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, OR FOR LOSS OF PROFITS OR LOST DATA (EVEN
            IF JENNA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), ARISING OUT OF, RELATING TO, OR IN ANY WAY
            CONNECTED WITH THE SERVICES, INCLUDING WITHOUT LIMITATION, THE SITE, CONTENT THESE TERMS OR THE PRIVACY
            POLICY. YOUR SOLE REMEDY FOR DISSATISFACTION WITH OUR SERVICES IS TO STOP USING OUR SERVICES. THESE
            LIMITATIONS SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW.
        </p>
        <p className={`${CLASS}-text`}>
            IN NO EVENT SHALL OUR CUMULATIVE LIABILITY OR THAT OF OUR OFFICERS, DIRECTORS, EMPLOYEES, PARENTS, PARTNERS,
            SUCCESSORS, AGENTS, AFFILIATES, SUBSIDIARIES AND THEIR RELATED COMPANIES EXCEED $50. YOU AGREE THAT THIS IS
            A FAIR LIMITATION BASED UPON THE MANNER AND COST BY WHICH OUR SERVICES ARE PROVIDED TO YOU. FURTHERMORE, YOU
            AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF, RELATED TO, OR IN ANY WAY CONNECTED WITH OUR SERVICES OR
            THESE TERMS AND CONDITIONS MUST COMMENCE WITHIN SIX (6) MONTHS AFTER THE CAUSE OF ACTION ACCRUES. CAUSES OF
            ACTION BROUGHT OUTSIDE THIS TIME PERIOD ARE WAIVED.
        </p>
        <h4 className={`${CLASS}-titles`}>10. Indenification</h4>
        <p className={`${CLASS}-text`}>
            You agree to indemnify and hold harmless us and our officers, directors, employees, parents, partners,
            successors, agents, distribution partners, affiliates, subsidiaries and their related companies from and
            against any and all claims, liabilities, losses, damages, obligations, costs and expenses (including
            reasonable attorneys' fees and costs) arising out of or related to: (i) your access to or use of our
            Services; (ii) any actual or alleged violation or breach by you of these Terms; (iii) any actual or alleged
            breach of any representation, warranty or covenant that you have made to us; and/or (iv) your acts or
            omissions. You agree to fully cooperate with us in the defense of any claim that is the subject of your
            obligations hereunder.
        </p>
        <h4 className={`${CLASS}-titles`}>11. Confidentiality</h4>
        <p className={`${CLASS}-text`}>
            You acknowledge that you may have access to certain confidential information of Jenna concerning Jenna’s
            business, plans, customers, technology, and products, and other information held in confidence by Jenna (“
            Confidential Information ”). Confidential Information will include all information in tangible or intangible
            form that under the circumstances of its disclosure, should reasonably be considered confidential. You agree
            not to use Confidential Information of Jenna to benefit any third party or disclose to any third party any
            of Jenna’s Confidential Information. You agree to take reasonable precautions to protect the confidentiality
            of such information, using no less than reasonable care. Upon termination or expiration of this Agreement
            for any reason, you will return to Jenna or destroy, at Jenna’s request, all Confidential Information of
            Jenna and all documents or media containing any such Confidential Information and any and all copies or
            extracts thereof.
        </p>
        <h4 className={`${CLASS}-titles`}>12. Governing Law; Jurisdiction</h4>
        <p className={`${CLASS}-text`}>
            a) These Terms shall be construed in accordance with the laws of the State of New York without regard to its
            conflict of laws rules.
        </p>
        <p className={`${CLASS}-text`}>
            b) Any claim or dispute between you and us that arises in whole or in part from your use of the Services or
            the Site shall be decided exclusively by a court of competent jurisdiction located in New York, NY, and you
            hereby consent to, and irrevocably waive all defenses of lack of personal jurisdiction and forum non
            conveniens with respect to venue and jurisdiction in the courts of New York, NY.
        </p>
        <p className={`${CLASS}-text`}>
            c) If there is a dispute between you and anyone accessing our Services, or you and any third party in
            connection with our Site or Services, you understand and agree that we are under no obligation to become
            involved. In such instances, you hereby release Jenna and its officers, directors, employees, parents,
            partners, successors, agents, affiliates, subsidiaries and their related companies from any and all claims,
            demands and damages of every kind or nature arising out of, relating to or in any way connected with such
            dispute.
        </p>
        <h4 className={`${CLASS}-titles`}>13. Copyright Infringement</h4>
        <p className={`${CLASS}-text`}>
            a) We may remove or disable, in our sole discretion, access to material on the Services or hosted on our
            systems that may be infringing third parties rights (including, without limitation, copyright of others).
        </p>
        <p className={`${CLASS}-text`}>
            b) If you believe that any material on the Site violates this Agreement or your intellectual property
            rights, please notify Jenna as soon as possible by sending an email to: contact@jenna.ai , with the
            following information: (i) a physical or electronic signature of the copyright owner or a person authorized
            to act on their behalf; (ii) identification of the copyrighted work claimed to have been infringed; (iii)
            identification of the material that is claimed to be infringing or to be the subject of infringing activity
            and that is to be removed or access to which is to be disabled, and information reasonably sufficient to
            permit us to locate the material; (iv) your contact information, including your address, telephone number,
            and an email address; (v) a statement by you that you have a good faith belief that use of the material in
            the manner complained of is not authorized by the copyright owner, its agent, or the law; and (vi) a
            statement that the information in the notification is accurate, and, under penalty of perjury, that you are
            authorized to act on behalf of the copyright owner.
        </p>
        <h4 className={`${CLASS}-titles`}>14. Copyright Infringement</h4>
        <p className={`${CLASS}-text`}>We may at any time update or modify these Terms.</p>
        <p className={`${CLASS}-text`}>
            b) Modifications to these Terms will be effective upon: (a) notice, either by posting on our Site or by
            email notification; and (b) your subsequent use of our Services. It is your responsibility to review these
            Terms from time to time for any changes. Your access and continued use of our Services following any
            modification of these Terms will signify your consent to and acceptance of the same.
        </p>
        <h4 className={`${CLASS}-titles`}>15. Copyright Infringement</h4>
        <p className={`${CLASS}-text`}>
            a) No waiver by either of us of any breach or default under these Terms shall be deemed to be a waiver of
            any preceding or subsequent breach or default. The section headings used in these Terms are for convenience
            only and shall not be given any legal import.
        </p>
        <p className={`${CLASS}-text`}>
            b) Except where specifically stated otherwise, if any part of these Terms are deemed unlawful or
            unenforceable for any reason, it is agreed that part of these Terms shall be stricken and that the remaining
            terms in these Terms shall not be affected and shall remain in force and effect.
        </p>
        <p className={`${CLASS}-text`}>
            c) You may not assign these Terms or any of your rights or obligations hereunder, in whole or in part,
            without our prior written consent.
        </p>
        <p className={`${CLASS}-text`}>
            d) These Terms (including the Privacy Policy incorporated by reference) constitute the entire agreement and
            supersede all previous written or oral agreements between you and Jenna in connection with the Services.
        </p>
    </div>
);

export default TermsOfService;
