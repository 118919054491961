const CLASS = 'jenna-user-avatar';

const UserAvatar = ({ className, candidateImg, hasImage, isLoading, handleImageClick }) => (
    <>
        <img className={`${CLASS}-image ${className}-image`} src={candidateImg} alt="user-avatar" />
        <div
            className={`${CLASS}-hover ${className}-hover ${hasImage ? 'uploaded' : 'placeholder'}`}
            onClick={handleImageClick}
        ></div>
        {isLoading && <div className={`${CLASS}-spinner ${className}-spinner`}></div>}
    </>
);

export default UserAvatar;
